<template>
<div>
    <div class="pb-12">
        <v-toolbar flat height="70" v-if="collapsed">
            <span class="title primary--text">Фільтр товарів</span>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="$store.commit('openCloseFiltersDrawer', false);">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <!-- Родительские категории -->
        <v-list nav dense v-if="parentCats.length" class="px-1">
            <v-list-item :key="item.id" v-for="item in parentCats" link @click="setCheckboxParentCat(item)" class="px-0">
                <v-list-item-content class="pa-0">
                    <v-list-item-subtitle class="pr-1">
                        <v-row align="center" class="py-2 px-2">
                            <v-checkbox v-model="checkboxParent[item.id]" hide-details class="filter-item shrink my-1 ml-4 pt-0">
                                <template v-slot:label>
                                    <span class="subtitle-2">{{item.name}}</span>
                                </template>
                            </v-checkbox>
                        </v-row>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>

        <!-- Тип сумісного товару -->
        <v-list nav dense v-if="analogs" class="px-1">
            <v-list-group v-model="type" active-class="grey--text">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-2 grey--text text--darken-3">Тип сумісного товару</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item @click="setAnalogsType()" class="px-0">
                    <v-list-item-content class="pa-0">
                        <v-list-item-subtitle class="pr-1">
                            <v-row align="center" class="py-2 px-2">
                                <v-checkbox v-model="checkboxAnalogsType" value="analog" hide-details class="shrink my-3 ml-4 pt-0">
                                    <template v-slot:label>
                                        <span class="subtitle-2">Аналоги</span>
                                    </template>
                                </v-checkbox>
                            </v-row>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="setAnalogsType()" class="px-0">
                    <v-list-item-content class="pa-0">
                        <v-list-item-subtitle class="pr-1">
                            <v-row align="center" class="py-2 px-2">
                                <v-checkbox v-model="checkboxAnalogsType" value="accessory" hide-details class="shrink my-3 ml-4 pt-0">
                                    <template v-slot:label>
                                        <span class="subtitle-2">Аксесуари</span>
                                    </template>
                                </v-checkbox>
                            </v-row>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="setAnalogsType()" class="px-0">
                    <v-list-item-content class="pa-0">
                        <v-list-item-subtitle class="pr-1">
                            <v-row align="center" class="py-2 px-2">
                                <v-checkbox v-model="checkboxAnalogsType" value="tying" hide-details class="shrink my-3 ml-4 pt-0">
                                    <template v-slot:label>
                                        <span class="subtitle-2">Сумісні товари</span>
                                    </template>
                                </v-checkbox>
                            </v-row>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </v-list>

        <!-- Вложенные категории -->
        <v-list nav dense v-if="!$route.query.query && !parentCats.length && nestedCats.length" class="px-1">
            <v-list-item :key="item.id" v-for="item in nestedCats" link @click="goTo(item)">
                <v-list-item-content>
                    <p v-if="nestedCats[0].id == item.id" class="mb-0">
                        <span class="grey--text body-2"> {{item.name}}</span>
                    </p>
                    <p v-if="nestedCats[0].id != item.id" class="mb-0">
                        <span class="subtitle-2 primary--text">{{item.name}}</span>
                    </p>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <!-- Категории акций -->
        <v-list nav dense v-if="actionCats.length && !nestedCats.length" class="px-1">
            <v-list-group v-model="availability" active-class="grey--text">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-2 grey--text text--darken-3">Група товарів</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item :key="item.id" v-for="item in actionCats" link @click="setCheckboxCatFilter(item)" class="px-1">
                    <v-list-item-content class="pa-0">
                        <v-list-item-subtitle class="pr-1">
                            <v-row align="center" class="py-2 px-2">
                                <v-checkbox v-model="checkboxCatFilter[item.id]" hide-details class="filter-item shrink my-3 ml-4 pt-0">
                                    <template v-slot:label>
                                        <span class="subtitle-2">{{item.name}}</span>
                                    </template>
                                </v-checkbox>
                            </v-row>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </v-list>

        <template v-if="(Object.keys($route.query).length > 0 && $route.query.constructor === Object) || checkboxAvailability == 'yes' || checkboxOnDemand == 'yes' || checkboxInTransit == 'yes'|| checkboxType == 'new' || checkboxType == 'du' || checkboxNewType == 'yes' || checkboxDuType == 'yes' || checkboxPriceoffType == 'yes'">
            <v-divider></v-divider>
            <v-chip class="ma-2" outlined @click="clearFilters()">
                <v-icon left small>mdi-filter-remove</v-icon>
                <span class="caption">Очистити фільтри</span>
            </v-chip>
        </template>

        <v-divider></v-divider>
        <!-- наявність -->
        <v-list nav dense class="px-1">
            <v-list-group v-model="availability" active-class="grey--text">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-2 grey--text text--darken-3">Наявність</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item @click="setAvailability()" class="px-0">
                    <v-list-item-content class="pa-0">
                        <v-list-item-subtitle class="pr-1">
                            <v-row align="center" class="py-2 px-2">
                                <v-checkbox v-model="checkboxAvailability" value="yes" hide-details class="filter-item shrink my-3 ml-4 pt-0">
                                    <template v-slot:label>
                                        <span class="subtitle-2">Є у залишках </span>
                                    </template>
                                </v-checkbox>
                            </v-row>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <template v-if="contactInfo.groups && contactInfo.groups.cl_corp_presale ? true : false">
                <v-list-item @click="setInTransit()" class="px-0">
                    <v-list-item-content class="pa-0">
                        <v-list-item-subtitle class="pr-1">
                            <v-row align="center" class="py-2 px-2">
                                <v-checkbox v-model="checkboxInTransit" value="yes" hide-details class="filter-item shrink my-3 ml-4 pt-0">
                                    <template v-slot:label>
                                        <span class="subtitle-2">Очікується</span>
                                    </template>
                                </v-checkbox>
                            </v-row>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="setOnDemand()" class="px-0">
                    <v-list-item-content class="pa-0">
                        <v-list-item-subtitle class="pr-1">
                            <v-row align="center" class="py-2 px-2">
                                <v-checkbox v-model="checkboxOnDemand" value="yes" hide-details class="filter-item shrink my-3 ml-4 pt-0">
                                    <template v-slot:label>
                                        <span class="subtitle-2">Під запит</span>
                                    </template>
                                </v-checkbox>
                            </v-row>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                </template>
            </v-list-group>
        </v-list>
        <v-divider></v-divider>
        <!-- Тип товару -->
        <v-list nav dense class="px-1">
            <v-list-group v-model="type" active-class="grey--text">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-2 grey--text text--darken-3">Тип товару</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item @click="setType()" class="px-0">
                    <v-list-item-content class="pa-0">
                        <v-list-item-subtitle class="pr-1">
                            <v-row align="center" class="py-2 px-2">
                                <v-checkbox v-model="checkboxNewType" value="yes" hide-details class="filter-item shrink my-3 ml-4 pt-0">
                                    <template v-slot:label>
                                        <span class="subtitle-2">Новий</span>
                                    </template>
                                </v-checkbox>
                            </v-row>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="setType()" class="px-0">
                    <v-list-item-content class="pa-0">
                        <v-list-item-subtitle class="pr-1">
                            <v-row align="center" class="py-2 px-2">
                                <v-checkbox v-model="checkboxDuType" value="yes" hide-details class="filter-item shrink my-3 ml-4 pt-0">
                                    <template v-slot:label>
                                        <span class="subtitle-2">Дефект пакування (ДУ) </span>
                                    </template>
                                </v-checkbox>
                            </v-row>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="setType()" class="px-0" v-if="!actions">
                    <v-list-item-content class="pa-0">
                        <v-list-item-subtitle class="pr-1">
                            <v-row align="center" class="py-2 px-2">
                                <v-checkbox v-model="checkboxPriceoffType" value="yes" hide-details class="filter-item shrink my-3 ml-4 pt-0">
                                    <template v-slot:label>
                                        <span class="subtitle-2">Уцінка</span>
                                    </template>
                                </v-checkbox>
                            </v-row>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </v-list>

        <v-divider></v-divider>
        <!-- Цена -->
        <v-list nav dense class="px-1">
            <v-list-group v-model="price" active-class="grey--text">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-2 grey--text text--darken-3">Цiна</v-list-item-title>
                    </v-list-item-content>
                </template>
                <div>
                    <input v-model="priceFrom" class="inp-cntx" @change="setRange()"> - <input v-model="priceTo" class="inp-cntx" @change="setRange()">
                    <v-btn @click="setRange()" class="ma-2" tile small color="teal" icon :disabled="priceChanged">
                        <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-range-slider hide-details v-model="priceRange" :min="priceMin" :max="priceMax"></v-range-slider>
                </div>
            </v-list-group>
        </v-list>

        <v-divider v-if="brandsList.length"></v-divider>
        <!-- Доступные бренды -->
        <v-list nav dense class="px-1" v-if="brandsList.length">
            <v-list-group v-model="brands" active-class="grey--text">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-2 grey--text text--darken-3">Виробник</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-container style="max-height: 400px" class="overflow-y-auto px-0">
                    <v-list-item :key="item.id" v-for="item in brandsList" link @click="setCheckboxBrand(item)" class="px-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-subtitle class="pr-1">
                                <v-row align="center" class="py-2 px-2">
                                    <v-checkbox v-model="checkboxBrand[item.brandId]" hide-details class="filter-item shrink my-3 ml-4 pt-0" :disabled="item.brandCnt == 0">
                                        <template v-slot:label>
                                            <span class="subtitle-2">{{item.brandName}}<span v-if="!checkboxBrand[item.brandId] && item.brandCnt != 0" class="grey--text pl-1"> ({{addBrand ? '+':''}}{{item.brandCnt}})</span></span>
                                        </template>
                                    </v-checkbox>
                                </v-row>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-container>
            </v-list-group>
        </v-list>
        <v-divider></v-divider>
        <!-- характеристики -->
        <template v-for="item in props">
            <v-list nav dense :key="item.id" v-if="propsData[item.id]" three-line class="px-1">
                <v-list-group v-model="item.active" active-class="grey--text">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-subtitle class="subtitle-2 grey--text text--darken-3">{{entities.decode(item.name)}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <v-container style="max-height: 400px" class="overflow-y-auto px-0">
                        <v-list-item :key="prop.id" v-for="prop in propsData[item.id]" link @click="setProps()" class="px-1">
                            <v-list-item-content class="pa-0">
                                <v-list-item-subtitle class="pr-1">
                                    <v-row align="center" class="py-2 px-2">
                                        <v-checkbox v-model="checkboxProps[prop.id]" hide-details class="filter-item shrink my-3 ml-4 pt-0" :disabled="prop.number == 0 ? true : false">
                                            <template v-slot:label>
                                                <span class="subtitle-2">{{prop.name}}<span v-if="!checkboxProps[prop.id] && prop.number != 0" class="grey--text pl-1"> ({{propsDefined[item.id] ? '+':'' }}{{prop.number}})</span></span>
                                            </template>
                                        </v-checkbox>
                                    </v-row>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-container>
                </v-list-group>
            </v-list>
            <v-divider v-if="propsData[item.id]"></v-divider>
        </template>
        <!-- характеристики числовые -->
        <!-- {{rangeNumeric}}
        {{rangeNumericFrom}}
        {{rangeNumericTo}} -->

        <template v-if="propsNumeric && propsNumeric.length > 0 && propsNumericData[item.id] && !(propsNumericData[item.id][0].min == 0 && propsNumericData[item.id][0].max == 0)" v-for="item in propsNumeric">
            <v-list nav dense :key="item.id" v-if="propsNumericData[item.id]" three-line class="px-1">
                <v-list-group v-model="item.active" active-class="grey--text">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-subtitle class="subtitle-2 grey--text text--darken-3">{{item.name}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <div>
                        <input v-model="rangeNumericFrom[item.id]" class="inp-cntx" @change="setNumericProps()"> - <input v-model="rangeNumericTo[item.id]" class="inp-cntx" @change="setNumericProps()">
                        <v-btn v-if="!collapsed" @click="setNumericProps()" class="ma-2" tile small color="teal" icon :disabled="propsNumericData[item.id][0].max == 0 || rangeNumericChanged[item.id]">
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                        <v-range-slider step="1" :color="propsNumericData[item.id][0].max == 0 ? 'grey' : ''" hide-details v-model="rangeNumeric[item.id]" :min="propsNumericData[item.id][0].min" :max="propsNumericData[item.id][0].max"></v-range-slider>
                    </div>
                </v-list-group>
            </v-list>
            <v-divider v-if="propsNumericData[item.id]"></v-divider>
        </template>
    </div>
    <v-bottom-navigation v-model="bottomNav" v-if="collapsed" absolute>
        <v-spacer></v-spacer>
        <v-btn value="recent" @click="$store.commit('openCloseFiltersDrawer', false)">
            <span>Показати</span>
            <v-icon>mdi-filter-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
    </v-bottom-navigation>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'

const Entities = require('html-entities').XmlEntities;

const qs = require('qs');

export default {
    props: {
        search: Boolean,
        analogs: Boolean,
        collapsed: Boolean,
        duPriceoff: Boolean,
        actions: Boolean
    },
    watch: {
        '$route.params.id'() {
            this.checkboxBrand = {}
            this.getNestedCats(this.$route.params.id)
            this.clearFilters()
            this.folding()

        },
        '$route.query.query'() {
            this.clearFilters()
            this.folding()
        },
        priceMin() {
            this.priceRange = [this.priceMin, this.priceMax]
            this.priceTo = this.priceMax
            this.priceFrom = this.priceMin
            this.folding()
        },
        priceRange() {
            this.priceFrom = this.priceRange[0]
            this.priceTo = this.priceRange[1]
            this.folding()
        },
        '$store.state.catalog.filtersLoaded'(v, o) {
            if (v) {
                this.initNumeric()
                this.initPrice()
                this.folding()
            }
        },
        '$store.state.catalog.filtersDrawerOpened'(v) {
            this.$vuetify.goTo(0)
            this.folding()
        },
        'rangeNumeric': {
            handler: function (after, before) {
                for (let id in after) {
                    this.$set(this.rangeNumericFrom, id, after[id][0])
                    this.$set(this.rangeNumericTo, id, after[id][1])
                }
            },
            deep: true
        }
    },
    data() {
        return {
            timeout: 500,
            checkboxBrand: {},
            checkboxParent: {},
            checkboxCatFilter: {},
            checkboxAvailability: NaN,
            checkboxInTransit: NaN,
            checkboxOnDemand: NaN,
            checkboxType: NaN,
            checkboxAnalogsType: NaN,
            checkboxProps: {},
            timerId: 0,
            priceRange: [],
            priceFrom: 0,
            priceTo: 0,
            rangeNumeric: {},
            rangeNumericFrom: {},
            rangeNumericTo: {},
            propsDefined: {},
            newQuery: {},
            bottomNav: true,
            propsNumericDataChanges: {},
            entities: new Entities(),
            checkboxPriceoffType: NaN,
            checkboxDuType: 'yes',
            checkboxNewType: 'yes'
        }
    },
    methods: {
        ...mapActions(['getNestedCats']),
        goTo(v) {
            if (this.$route.params.id != v.id) {
                this.$router.push(`/list/${v.id}`)
            }
        },
        setNewQuery() {
            this.$router.push({
                path: '',
                query: this.newQuery
            })
            this.newQuery = {}
        },
        setType() {
            clearTimeout(this.timerId)
            this.timerId = setTimeout(() => {
                delete this.$route.query['newtype']
                delete this.$route.query['typedu']
                delete this.$route.query['priceoff']
                Object.assign(this.newQuery, {
                    ...this.$route.query,
                    typedu: this.checkboxDuType ? 'yes' : (!this.checkboxDuType && !this.checkboxPriceoffType &&  !this.checkboxNewType ? 'yes' : undefined),
                    priceofftype: this.checkboxPriceoffType ? 'yes' : (!this.checkboxDuType && !this.checkboxPriceoffType &&  !this.checkboxNewType ? 'yes' : undefined),
                    newtype: this.checkboxNewType ? 'yes' : (!this.checkboxDuType && !this.checkboxPriceoffType &&  !this.checkboxNewType ? 'yes' : undefined),
                })
                this.setNewQuery()
            }, 1000)
        },
        initTypes() {
            Object.assign(this.newQuery, {
                ...this.$route.query,
                newtype: 'yes',
                typedu: 'yes'
            })
            this.setNewQuery()
        },
        setAnalogsType() {
            clearTimeout(this.timerId)
            this.timerId = setTimeout(() => {
                delete this.$route.query['analogType']
                Object.assign(this.newQuery, {
                    ...this.$route.query,
                    analogType: this.checkboxAnalogsType || undefined
                })
                sessionStorage.type = this.checkboxAnalogType
                this.setNewQuery()
            }, this.timeout)
        },
        setCheckboxBrand() {
            clearTimeout(this.timerId)
            this.timerId = setTimeout(() => {
                const brands = []
                for (let id in this.checkboxBrand) {
                    if (this.checkboxBrand[id]) brands.push(id)
                }
                delete this.$route.query['brands']
                Object.assign(this.newQuery, {
                    ...this.$route.query,
                    brands: brands.join(',') || undefined
                })
                this.setNewQuery()
            }, this.timeout)
        },
        setCheckboxParentCat() {
            clearTimeout(this.timerId)
            this.timerId = setTimeout(() => {
                const cats = []
                for (let id in this.checkboxParent) {
                    if (this.checkboxParent[id]) cats.push(id)
                }
                delete this.$route.query['parents']
                Object.assign(this.newQuery, {
                    ...this.$route.query,
                    parents: cats.join(',') || undefined
                })
                this.setNewQuery()
            }, this.timeout)
        },
        setCheckboxCatFilter() {
            clearTimeout(this.timerId)
            this.timerId = setTimeout(() => {
                const cats = []
                for (let id in this.checkboxCatFilter) {
                    if (this.checkboxCatFilter[id]) cats.push(id)
                }
                delete this.$route.query['categories']
                Object.assign(this.newQuery, {
                    ...this.$route.query,
                    categories: cats.join(',') || undefined
                })
                this.setNewQuery()
            }, this.timeout)
        },
        setAvailability() {
            clearTimeout(this.timerId)
            this.timerId = setTimeout(() => {
                delete this.$route.query['available']
                Object.assign(this.newQuery, {
                    ...this.$route.query,
                    available: this.checkboxAvailability || undefined
                })
                this.setNewQuery()
            }, this.timeout)
        },
        setInTransit() {
            clearTimeout(this.timerId)
            this.timerId = setTimeout(() => {
                delete this.$route.query['intransit']
                Object.assign(this.newQuery, {
                    ...this.$route.query,
                    intransit: this.checkboxInTransit || undefined
                })
                this.setNewQuery()
            }, this.timeout)
        },
        setOnDemand() {
            clearTimeout(this.timerId)
            this.timerId = setTimeout(() => {
                delete this.$route.query['ondemand']
                Object.assign(this.newQuery, {
                    ...this.$route.query,
                    ondemand: this.checkboxOnDemand || undefined
                })
                this.setNewQuery()
            }, this.timeout)
        },
        setAvailabilityInSession() {
            clearTimeout(this.timerId)
            this.timerId = setTimeout(() => {
                delete this.$route.query['available']
                Object.assign(this.newQuery, {
                    ...this.$route.query,
                    available: this.checkboxAvailability || undefined
                })
                sessionStorage.available = this.checkboxAvailability
                this.setNewQuery()
            }, this.timeout)
        },
        clearFilters() {
            const query = this.$route.query.query
            this.$router.push({
                path: query ? `?query=${query}` : undefined,
            }, () => {

            })
            this.clearFiltersParams()
        },
        clearFiltersParams() {
            this.checkboxBrand = {}
            this.checkboxAvailability = NaN
            this.checkboxOnDemand = NaN
            this.checkboxInTransit = NaN
            this.priceRange = [this.priceMin, this.priceMax]
            this.checkboxType = NaN
            this.checkboxNewType = 'yes'
            this.checkboxDuType = 'yes'
            this.checkboxPriceoffType = NaN
            this.checkboxProps = {}
            this.checkboxParent = {}
            this.newQuery = {}
            this.checkboxCatFilter = {}
        },
        setRange() {
            this.priceRange = [this.priceFrom, this.priceTo]
            delete this.$route.query['priceRange']
            Object.assign(this.newQuery, {
                ...this.$route.query,
                priceRange: `${this.priceFrom}-${this.priceTo}`
            })
            this.setNewQuery()
        },
        setProps() {
            clearTimeout(this.timerId)
            this.timerId = setTimeout(() => {
                const props = []
                const valuesProps = {}
                for (let id in this.checkboxProps) {
                    if (this.checkboxProps[id]) props.push(id)
                }
                for (let i in this.props) {
                    const prop_id = this.props[i].id
                    for (let value of props) {
                        if (!this.propsData[prop_id]) continue
                        const pr = this.propsData[prop_id].find(el => el.id == value)
                        if (pr) {
                            if (!valuesProps[prop_id]) valuesProps[prop_id] = []
                            valuesProps[prop_id].push(value)
                        }
                    }
                }
                const propsArr = []
                this.propsDefined = {}
                for (let prop_id in valuesProps) {
                    this.propsDefined[prop_id] = true
                    propsArr.push(prop_id + '_' + valuesProps[prop_id].join('.'))
                }

                delete this.$route.query['props']
                Object.assign(this.newQuery, {
                    ...this.$route.query,
                    props: propsArr.join(',') || undefined
                })

                this.setNewQuery()
            }, this.timeout)
        },
        setNumericProps() {
            delete this.$route.query['numeric']
            const numeric = []
            for (let id in this.rangeNumeric) {
                this.rangeNumericFrom[id] = Number(String(this.rangeNumericFrom[id]).replace(/\,/, '.'))
                this.rangeNumericTo[id] = Number(String(this.rangeNumericTo[id]).replace(/\,/, '.'))
                this.rangeNumeric[id] = [this.rangeNumericFrom[id], this.rangeNumericTo[id]]
                if (this.propsNumericData[id][0].min != this.rangeNumeric[id][0] || this.propsNumericData[id][0].max != this.rangeNumeric[id][1]) {
                    numeric.push(id + '_' + this.rangeNumeric[id][0] + '-' + this.rangeNumeric[id][1])
                }
            }

            Object.assign(this.newQuery, {
                ...this.$route.query,
                numeric: numeric.join(',') || undefined
            })
            this.setNewQuery()
        },
        // инициализация чекобоксов в зависимости от строки запроса
        parseQuerySet() {
            const q = this.$route.query
            this.checkboxAvailability = q.available || ''
            this.checkboxOnDemand = q.ondemand || ''
            this.checkboxInTransit = q.intransit || ''
            this.checkboxType = q.type || ''
            this.checkboxNewType = (!q.newtype && !q.typedu && !q.priceofftype) ? 'yes' : q.newtype
            this.checkboxDuType = (!q.newtype && !q.typedu && !q.priceofftype) ? 'yes' : q.typedu
            this.checkboxPriceoffType = q.priceofftype || ''
            const brands = q.brands && q.brands.split(',')

            if (brands) {
                for (let id of brands) {
                    this.checkboxBrand[id] = true
                }
            }
            const parents = q.parents && q.parents.split(',')
            if (parents) {
                for (let id of parents) {
                    this.checkboxParent[id] = true
                }
            }
            const props = q.props && q.props.split(',')
            if (props) {
                for (let el of props) {
                    const [prop_id, val] = el.split('_')
                    const values = val.split('.')
                    for (let value of values) {
                        this.checkboxProps[value] = true
                    }
                }
            }
            const categories = q.categories && q.categories.split(',')
            if (categories) {
                for (let el of categories) {
                    this.checkboxCatFilter[el] = true
                }
            }

        },
        initNumeric() {
            this.rangeNumeric = {}
            if (this.propsNumeric) {
                for (let el of this.propsNumeric) {
                    if (this.propsNumericData[el.id]) {
                        this.$set(this.rangeNumericFrom, el.id, this.propsNumericData[el.id][0].min)
                        this.$set(this.rangeNumericTo, el.id, this.propsNumericData[el.id][0].max)
                        this.$set(this.rangeNumeric, el.id, [this.propsNumericData[el.id][0].min, this.propsNumericData[el.id][0].max])
                    }
                }
                const q = this.$route.query
                const numeric = q.numeric && q.numeric.split(',')
                if (numeric) {
                    for (let pr of numeric) {
                        const [prop_id, range] = pr.split('_')
                        const [min, max] = range.split('-')
                        this.rangeNumeric[prop_id] = [min, max]
                    }
                }
            }
        },
        initPrice() {
            const q = this.$route.query
            const price = q.priceRange
            if (price) {
                const [min, max] = price.split('-')
                this.priceRange = [min, max]
            }
        },
        folding() {
            //сворачивание неактивных параметров в режиме телефона
            if (this.collapsed) {

                this.props.map(el => el.active = false)
                this.propsNumeric.map(el => el.active = false)
                const props = []
                for (let id in this.checkboxProps) {
                    if (this.checkboxProps[id]) props.push(id)
                }
                for (let i in this.props) {
                    const prop_id = this.props[i].id
                    for (let value of props) {
                        if (!this.propsData[prop_id]) continue
                        const pr = this.propsData[prop_id].find(el => el.id == value)
                        if (pr) {
                            this.props.map(el => {
                                if (el.id == prop_id) {
                                    el.active = true
                                }
                            })
                        }
                    }
                }
                for (let id in this.rangeNumeric) {
                    if (this.propsNumericData[id][0].min != this.rangeNumeric[id][0] || this.propsNumericData[id][0].max != this.rangeNumeric[id][1]) {
                        this.propsNumeric.map(el => {
                            if (el.id == id) {
                                el.active = true
                            }
                        })
                    }
                }

            }
        }
    },
    created() {
        this.checkboxAvailability = ''
        this.checkboxOnDemand = ''
        this.checkboxInTransit = ''
        this.checkboxType = ''
        this.initNumeric()
        this.getNestedCats(this.$route.params.id)
        this.parseQuerySet()

        // this.checkboxAvailability = sessionStorage.available
        // this.checkboxType = sessionStorage.type
    },
    mounted() {
        this.folding()
    },
    computed: {
        brands: {
            get() {
                return this.collapsed ? false : true
            },
            set(v) {}
        },
        price: {
            get() {
                return this.collapsed ? false : true
            },
            set(v) {}
        },
        availability: {
            get() {
                return this.collapsed ? false : true
            },
            set(v) {}
        },
        type: {
            get() {
                return this.collapsed ? false : true
            },
            set(v) {}
        },
        nestedCats() {
            return this.$store.state.catalog.nestedCats
        },
        brandsList() {
            return this.$store.state.catalog.catalogListBrands
        },
        parentCats() {
            return this.$store.state.catalog.parentCats
        },
        actionCats() {
            return this.$store.state.catalog.actionCats
        },
        priceMin() {
            return this.$store.state.catalog.priceMin
        },
        priceMax() {
            return this.$store.state.catalog.priceMax
        },
        props() {
            return this.$store.state.catalog.props
        },
        propsData() {
            return this.$store.state.catalog.propsData
        },
        propsNumeric() {
            return this.$store.state.catalog.propsNumeric
        },
        propsNumericData() {
            return this.$store.state.catalog.propsNumericData
        },
        filtersLoaded() {
            return this.$store.state.catalog.filtersLoaded
        },
        addBrand() {
            let find = false
            for (let id in this.checkboxBrand) {
                if (this.checkboxBrand[id]) find = true
            }
            return find
        },
        priceChanged() {
            let priceRangeFrom = this.priceMin || 0
            let priceRangeTo = this.priceMax || 0
            if (this.$route.query.priceRange) {
                [priceRangeFrom, priceRangeTo] = this.$route.query.priceRange.split('-')
            }
            if (this.priceTo != priceRangeTo || this.priceFrom != priceRangeFrom) {
                return false
            } else {
                return true
            }
        },
        rangeNumericChanged() {
            for (let id in this.propsNumericData) {
                this.propsNumericDataChanges[id] = false
            }
            let q = this.$route.query
            const numeric = q.numeric && q.numeric.split(',')
            const currentData = {}
            for (let id in this.rangeNumeric) {
                currentData[id] = [this.propsNumericData[id][0].min, this.propsNumericData[id][0].max]
            }
            if (numeric) {
                for (let el of numeric) {
                    const [prop_id, val] = el.split('_')
                    currentData[prop_id] = val.split('-')
                }
            }
            for (let id in this.rangeNumeric) {
                if (this.rangeNumeric[id] && (this.rangeNumeric[id][0] != currentData[id][0] || this.rangeNumeric[id][1] != currentData[id][1])) {
                    this.propsNumericDataChanges[id] = false
                } else {
                    this.propsNumericDataChanges[id] = true
                }
            }
            return this.propsNumericDataChanges
        },
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
    },
}
</script>

<style>
.inp-cntx {
    margin-left: 3px;
    margin-right: 3px;
    border: 1px solid #ccc;
    width: 60px;
    text-align: center;
    padding: 1px;
    border-radius: 4px;
    box-sizing: border-box;
    color: grey
}

.v-list--dense .v-list-item {
    min-height: 20px;
}

.filter-item .v-input__slot {
    align-items: start;
}

.filter-item .v-input__slot span {
    white-space: normal;
}
</style>
